import '@fortawesome/fontawesome-svg-core/styles.css';
import './styles/index.css';
import './styles/syntax-highlighting.css';

import { SessionProvider } from 'next-auth/react';
import { Theme } from '@scale/scaleui-radix';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ThemesDocsPage } from '@components/ThemesDocsPage';
import { Favicon } from '@components/Favicon';
import { CssLibPreferenceProvider } from '@components/CssLibPreference';

function Pages({ Component, pageProps }: AppProps) {
  const router = useRouter();

  if (router.pathname.startsWith('/themes/docs')) {
    return (
      <Theme>
        <ThemesDocsPage>
          <Favicon />
          <Component {...pageProps} />
        </ThemesDocsPage>
      </Theme>
    );
  }

  return (
    <Theme>
      <Favicon />
      <Component {...pageProps} />
    </Theme>
  );
}

function App(props: AppProps) {
  return (
    <SessionProvider session={props.pageProps.session}>
      <CssLibPreferenceProvider>
        <ThemeProvider
          attribute="class"
          disableTransitionOnChange
          enableSystem={false}
          value={{
            light: 'light-theme',
            dark: 'dark-theme',
            duneLight: 'light-theme',
            duneDark: 'dark-theme',
            outlierDark: 'dark-theme',
            outlierLight: 'light-theme',
          }}
        >
          <Pages {...props} />
        </ThemeProvider>
      </CssLibPreferenceProvider>
    </SessionProvider>
  );
}

export default App;
